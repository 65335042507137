@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,300&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Ubuntu", sans-serif !important;
}

a,
p,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6,
* {
  font-family: "Ubuntu", sans-serif !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.fullwidth {
  width: 100%;
}

ul {
  padding-inline-start: 10px;
}

@media screen and (min-width: 1200px) and (max-width: 2600px) {
  .MuiContainer-root {
    max-width: 1760px !important;
    /* zoom: 95%; */
  }
}

.MuiTextField-root {
  background: white;
}

.MuiFormHelperText-root {
  color: red;
  background: #ff9e9e;
  padding: 1px 4px;
  margin: 0 !important;
  border-radius: 0px 0px 10px 5px;
  margin-top: -3px !important;
  z-index: 0;
}

/* hard coded fix */
[data-popper-placement] {
  z-index: 1;
}

h4,
h5,
h6 {
  font-family:
    Open sans,
    sans-serif;
  font-weight: 400 !important;
  color: #2058e8e3;
}

.MuiDrawer-paper {
  border-left: 2px solid #1565c0;
  background: #f4f4f4 !important;
}

.MuiButtonBase-root.cancel-btn {
  background: #dbdbdb !important;
  color: #727272 !important;
  border: 0;
}

.MuiButton-root {
  box-shadow: none !important;
}

.hidden {
  display: none !important;
}

.flex-10 {
  display: flex;
  gap: 10px;
}

.flex-end {
  justify-content: end;
}

.Mui-expanded {
  min-height: auto !important;
}

.MuiAccordionSummary-content {
  display: flex !important;
  align-items: "center" !important;
}

.MuiAccordionSummary-contentGutters {
  display: flex !important;
  align-items: center !important;
}

.MuiAccordion-region .MuiAccordionDetails-root {
  padding: 8px 16px;
}

.d-flex {
  display: flex;
}

.gap-5 {
  gap: 5px;
}

.align-center {
  display: flex !important;
  align-items: center !important;
}

.align-bottom {
  display: flex;
  align-items: end;
}

.justify-vertically {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.MuiAccordion-root.mini .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0px 0;
}

.MuiAccordion-root.mini .MuiAccordionSummary-root {
  min-height: 27px;
}

.MuiAccordion-root.mini .MuiAccordionDetails-root {
  padding: 1px 16px 16px;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.fit-center {
  margin: 0 auto;
  width: fit-content;
}

#notifications-menu ul {
  padding: 0;
  padding-top: 8px;
}

.mouse-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.mouse-hover.no-underline:hover {
  cursor: pointer;
  text-decoration: none;
}

.invisible {
  visibility: hidden !important;
}

.ul-no-margins {
  margin: 0px 11px;
  padding: 0;
}

.MuiIconButton-root.active {
  background: #2059e8;
  color: white;
}

.MuiIconButton-root.active:hover {
  background: #2059e8;
  color: white;
}

.margin-auto {
  margin: 0 auto;
}

.flatpickr-input {
  display: none;
}

.MuiButton-containedPrimary svg {
  color: white;
}
/**
Flatpicker
**/
.flatpickr-months .flatpickr-month {
  background: #2059e8 !important;
}

.flatpickr-weekdays {
  background: #2059e8 !important;
}

span.flatpickr-weekday {
  background: #2059e8 !important;
  color: white !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: #2059e8 !important;
}

.flatpickr-day.selected {
  background: #2059e8 !important;
  border-color: #2059e8 !important;
}

.MuiPaper-root.ov-visible {
  overflow: visible !important;
}

.MuiButtonBase-root.MuiButton-containedError {
  background: #dbdbdb;
  color: #727272;
}
.MuiButtonBase-root.MuiButton-containedError:hover {
  background: #dbdbdb;
  color: #727272;
}
.MuiAccordionSummary-content {
  margin: 6px 0 !important;
  min-height: 0 !important;
}

.overlay-universal {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.456);
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
}
